<script setup lang="ts">
import {getLocalizedAppInfoArray, type LocalizedAppInfo} from "~/mylib/AppInfo";

const langArray = ["ja", "en", "zh_hans", "zh_hant", "ko"]
let localizedAppInfoMap: {[key: string]: LocalizedAppInfo[]} = {}
for (const lang of langArray) {
  localizedAppInfoMap[lang] = getLocalizedAppInfoArray(lang)
}
</script>

<template>
  <div>
    <div v-for="lang in langArray">
      <h2>{{lang}}</h2>
      <ul>
        <li v-for="appInfo in localizedAppInfoMap[lang]">
          <span>{{appInfo.appName}}</span>&nbsp;|
          <a :href="`/app/${appInfo.appId}/${lang}/privacy_policy`">Privacy Policy</a>&nbsp;|
          <a :href="`/app/${appInfo.appId}/${lang}/term_of_use`">Term of Use</a>
        </li>
      </ul>
    </div>
  </div>
</template>
